// hooks/useDeviceAnalytics.js
import { useEffect } from "react";
import { setUserProperties, logEvent } from "firebase/analytics";
import UAParser from "ua-parser-js";
import { analytics } from "config/firebase.js";

function getDeviceInfo() {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    device: result.device.type || "desktop", // 'mobile', 'tablet', or 'desktop'
    os: result.os.name || "unknown", // e.g., 'Windows', 'iOS', 'Android'
    osVersion: result.os.version || "unknown", // e.g., '10', '14.2'
    browser: result.browser.name || "unknown", // e.g., 'Chrome', 'Safari'
    browserVersion: result.browser.version || "unknown", // e.g., '89.0'
  };
}

function useDeviceAnalytics() {
  useEffect(() => {
    // Capture and set device info once when the component mounts
    const deviceInfo = getDeviceInfo();
    if (analytics) {
      setUserProperties(analytics, {
        device_type: deviceInfo.device,
        os_name: deviceInfo.os,
        os_version: deviceInfo.osVersion,
        browser_name: deviceInfo.browser,
        browser_version: deviceInfo.browserVersion,
      });
    }
  }, [analytics]);

  // Function to log events with optional custom parameters
  const logEventWithDeviceInfo = (eventName, additionalParams = {}) => {
    const deviceInfo = getDeviceInfo();
    if (analytics) {
      logEvent(analytics, eventName, {
        device_type: deviceInfo.device,
        os_name: deviceInfo.os,
        os_version: deviceInfo.osVersion,
        browser_name: deviceInfo.browser,
        browser_version: deviceInfo.browserVersion,
        ...additionalParams,
      });
    }
  };

  return { logEventWithDeviceInfo };
}

export default useDeviceAnalytics;
