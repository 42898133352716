import React, { useState } from "react";
import Link from "next/link";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { addEmail } from "api/emails.api";

const KeepInTouch = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  return (
    <div className="shadow-md rounded-md border px-10 md:px-36 py-12 mb-0 md:mb-4">
      <div className="flex flex-col mb-6">
        <span className="font-semibold text-[30px] md:text-[36px]">
          Մնա՜ կապի մեջ
        </span>
        <span className="text-gray-600 text-[18px] md:text-[24px]">
          Բաժանորդագվիր մեր փոստային ցանկին
        </span>
      </div>
      <div className="flex md:flex-row flex-col gap-10 items-center mt-20 mb-2">
        <div className="relative flex flex-col gap-2 w-full md:w-fit">
          <span className="absolute -top-10 text-[18px] md:text-[20px]">
            Անուն
          </span>
          <TextField
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            placeholder="Օրինակ Օրինակյան"
          />
        </div>
        <div className="relative flex flex-col gap-2 md:mt-0 mt-14 w-full md:w-fit">
          <span className="absolute -top-10 text-[18px] md:text-[20px]">
            Էլ․ Հասցե
          </span>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            size="small"
            placeholder="example@example.com"
          />
        </div>
        <div className="flex flex-col gap-2 w-full md:w-fit">
          <Button
            onClick={() => {
              addEmail(email, { email, name }).then(() => {
                setEmail("");
                setName("");
              });
            }}
            variant="contained"
          >
            <span className="normal-case px-10">Բաժանորդագրվել</span>
          </Button>
        </div>
      </div>
      <span className="text-xs text-gray-800 mt-10">
        Գրանցվելով՝ դուք համաձայնում եք մեր{" "}
        <Link href="/privacy-policy">
          <span className="underline">Գաղտնիության քաղաքականությանը</span>
        </Link>{" "}
        և
        <Link href="/terms-of-use">
          <span className="underline">Պայմանները ու դրույթները</span>
        </Link>
      </span>
    </div>
  );
};

export default KeepInTouch;
