import React from "react";
import Analyses from "./Analyses";
import Slider from "../slider/Slider";

const AnalysesContainer = ({ analyses, onClick }) => {
  return (
    <div className="">
      <div className="hidden md:flex">
        <Analyses analyses={analyses} onClick={onClick} />
      </div>
      <div className="block md:hidden">
        <Slider items={analyses} onClick={onClick} />
      </div>
    </div>
  );
};

export default AnalysesContainer;
