import React from "react";
import Image from "next/image";
import { MdOutlineNavigateNext } from "react-icons/md";

const Card = ({ item, onClick }) => {
  return (
    <div className="flex flex-col items-center md:gap-10 md:flex-row w-full md:h-[340px] hover:cursor-pointer">
      <Image
        width={340}
        height={340}
        className="w-[340px] h-[340px] object-cover rounded-lg"
        src={item.poster ? item.poster : "default_video.webp"}
        alt={item.title}
        priority
      />
      <div className="relative flex flex-col gap-4 h-[340px] p-10 w-full border rounded-2xl">
        <span onClick={onClick} className="font-bold text-md line-clamp-2 mt-2 hover:cursor-pointer hover:underline">
          {item.title}
        </span>
        <span className="text-sm line-clamp-6">{item.description}</span>
        <div
          onClick={onClick}
          className="absolute bottom-6 right-6 hover:cursor-pointer"
        >
          <MdOutlineNavigateNext color="#6E6E6E" size={40} />
        </div>
      </div>
    </div>
  );
};

export default Card;
