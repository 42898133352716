import React from "react";
import VerticalSlider from "components/slider/VerticalSlider";
import Slider from "components/slider/Slider";

const SpecialsContainer = ({ specials, onClick }) => {
  return (
    <div className="mt-20">
      <div className="md:block hidden">
        <VerticalSlider items={specials} onClick={onClick}/>
      </div>
      <div className="md:hidden block">
        <Slider showDots items={specials} onClick={onClick} />
      </div>
    </div>
  );
};

export default SpecialsContainer;
