import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

export const addEmail = async (id, data) => {
  if (!id) return;
  const docRef = doc(db, "emails", id);
  try {
    await setDoc(docRef, data, { merge: true });
  } catch (error) {
    console.error(`Error while adding emails collection ${error}`);
    return error;
  }
};
