import React from "react";
import { useRouter } from "next/router";
import KeepInTouch from "components/keep-in-touch/KeepInTouch";
import Banner from "components/banner/Banner";
import useDeviceAnalytics from "hooks/analytics/useDeviceAnalytics"

// Containers
import ProgramsContainer from "components/programs/ProgramsContainer";
import AnalysesContainer from "components/analyses/AnalysesContainer";
import SpecialsContainer from "components/specials/SpecialsContainer";
import EventsContainer from "components/events/EventsContainer";

// API
import { getMainContent } from "api/main.api";

const Main = ({ programs, analyses, specials, events }) => {
  const router = useRouter();
  useDeviceAnalytics();

  return (
    <div className="pb-4 lg:px-20 xl:px-40 px-4">
      {!!programs.length && (
        <ProgramsContainer
          programs={programs}
          onClick={(id) => router.push(`/programs/${id}`)}
        />
      )}

      <Banner />
      {!!analyses.length && (
        <AnalysesContainer
          analyses={analyses}
          onClick={(id) => router.push(`/analyses/${id}`)}
        />
      )}
      {!!specials.length && (
        <SpecialsContainer
          specials={specials}
          onClick={(id) => router.push(`/specials/${id}`)}
        />
      )}
      {!!events.length && (
        <EventsContainer
          events={events}
          onClick={(id) => router.push(`/events/${id}`)}
        />
      )}
      <KeepInTouch />
    </div>
  );
};

export async function getServerSideProps() {
  const mainContent = await getMainContent();
  const formatData = (data) => {
    return data.reduce((acc, category) => {
      acc[category.id] = category.content;
      return acc;
    }, {});
  };
  const { programs, analyses, specials, events } = formatData(mainContent);
  return {
    props: {
      programs,
      analyses,
      specials,
      events,
    },
  };
}

export default React.memo(Main);
