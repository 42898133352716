import React from "react";
import Slider from "../slider/Slider";
import Card from "components/card/Card";

const EventsContainer = ({ events, onClick }) => {
  return (
    <div className="mt-20 w-full">
      <div className="block md:hidden">
        <Slider items={events} onClick={onClick} />
      </div>
      <div className="hidden flex-col gap-20 pb-36 md:flex">
        {events.map((event) => {
          return (
            <Card
              key={event.id}
              item={event}
              onClick={() => onClick(event?.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default EventsContainer;
